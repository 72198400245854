.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.vertically-spaced * {
  margin-bottom: 10px;
  margin-top: 10px;
}

.text-left {
  text-align: left;
}

.padding-normal-right {
  padding: 30px 30px 30px 0;
}

.margin-top-small {
  margin-top: 5px;
}

.margin-horizontal-auto {
  margin-left: auto;
  margin-right: auto;
}

.ant-col {
  /* padding: 0px 15px; */
}

.text-right {
  text-align: right;
}

.work-area {
  /* max-width: max(calc(50% - 60px),300px); */
  width: 100%;
  min-width: min(300px, 100vw);
  padding: 0 16px;
}

.work-area.no-padding-right {
  padding-right: 0;
}

.work-area .ant-tabs .ant-tabs-content-holder {
  /* background-color: lightgrey; */
  box-shadow: 5px 5px 20px lightgrey;
  /* max-width: max(calc(40vw - 60px),300px); */
  min-width: min(300px, 100vw);

  height: 70vh;
  /* margin-right: 15px; */
  /* padding: 0 30px; */
}

.work-area:last-of-type .ant-tabs .ant-tabs-content-holder {
  /* margin-left: 15px; */
  margin-right: 0;
  border: 1px solid lightgrey;
  overflow: auto;
  padding: 0 15px;
}

.heading {
  padding: 0 10px;
}

.ant-row.no-inner-padding .ant-col {
  padding: 0 !important;
}

.document-view {
  padding: 0 10px;
}

.ant-tabs-top>.ant-tabs-nav {
  margin-bottom: 0;
}

.textOverflowEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.ant-menu-item.ant-menu-item {
  padding-right: 7px;
}

.text-huge {
  font-size: 1.5em;
}

.huge-buttons-grid {
  /* max-width: 800px; */
  margin-left: auto;
  margin-right: auto;
}

.huge-buttons-grid .ant-col {
  margin: 8px;
  max-width: 380px;
  height: 200px;
  word-wrap: normal;
}

.huge-buttons-grid-explainer {
  /* max-width: 620px; */
}

.huge-button {
  height: 200px;
  width: 100%;
  word-wrap: normal;
  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
  color: black;
  font-size: 1.2em;
}

.huge-button:hover {
  border: 1px solid #1890ff;
  /* color: white; */
  transition: 0.1s;
  margin-top: -2px;
  margin-left: -2px;
  box-shadow: 3px 3px 3px #188fff80;
}


.huge-button:active {
  /* background-color: #9a9a9a; */
  /* color: white; */
  border: 1px solid #1890ff;
  margin-top: -1px;
  margin-left: -1px;
  box-shadow: 1px 1px 1px #188fff80;
}

.content-narrow {
  max-width: min(800px, calc(100vw - 16px));
}

.content-main {
  min-height: 85vh;
}

.lg-only {
  display: none
}

h1 {
  margin-top: 12px;
  font-size: 1em;
}

@media screen and (min-width: 720px) {
  h1 {
    margin-top: 0px;
    font-size: 2em;
  }

  .lg-only {
    display: block
  }
}

.lg-only {}


.quote {
  display: block;
  font-style: italic;
  font-size: 1.1em;
  background-color: #f0f0f0;
  padding: 16px;
  margin: 10px;

}

.hero .text-shadow {
  color: #1368b8;
  text-shadow: 1px 1px 1px #1890ff;
}

.hero {
  text-align: center;
  padding: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-top: 40px;
}

@media screen and (min-width: 800px) {
  .hero {
    margin-bottom: 50px;
    margin-top: 50px;
  }      
}

q + a {
  font-style: italic;
  text-align: right;
}

h2 {
  margin-bottom: 16px;
}
p {
  margin-bottom: 24px;
  font-size: 1.2em;
  text-align: justify;
}

.margin-bottom {
  padding-bottom: 16px;;
}

.ant-layout-footer {
  margin-top : 20px;
  padding-left : 8px;
  padding: 8px;
  position: relative;
  bottom: 0;
  bottom: -14px;
  padding-bottom: 14px;
}

textarea.ant-input {
  font-family: monospace;
  font-size: 1em;
}